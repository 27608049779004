@import "../../../../../scss/base/var.module.scss";

.PageContainer {
  padding: 30px 25px; // 20px 15px + 10px this is how the repository pages did it
  background-color: $color-white;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 100%;
  overflow-y: auto;
}

.breadCrumbs {
  margin-bottom: 20px;
}

.container {
  display: grid;
  grid-template-columns: 1fr 3fr 2fr 1fr;
  grid-template-rows: 30px auto 50px auto;
  grid-template-areas:
    "Tile Tile Tile Icon"
    "Tile Tile Tile ."
     ". . Buttons Buttons"
"Content Content Content Content";
  gap: 2px 2px;
  margin: 30px
}

.Tile {
  grid-area: Tile;
  justify-self: flex-start;
}

.body {
  display: flex;
  flex-direction: row;
  margin-bottom: auto;

  .iconContainer {
    width: 40px;
    height: 40px;
    overflow: hidden;
    flex-shrink: 0;

    .icon {
      width: 40px;
      height: 40px;
    }
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    .title {
      display: block;
      font-weight: 500;
      margin-bottom: 5px;
    }

    .description {
      display: block;
    }
  }
}

.Title {
  grid-area: Title;
  margin-top: 5px;
  justify-self: flex-start;
}

.ShortDesc {
  grid-area: ShortDesc;
  margin-right: 10px;
  justify-self: flex-start;

}

.Buttons {
  grid-area: Buttons;
  margin-top: 20px;
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: flex-end;
}

.Desc {
  grid-area: Desc;
  overflow: auto;
  display: grid;
  grid-template-columns: 90px 100px 50px;
  justify-content: space-evenly;

}

.ReadmeBlock {
  grid-area: Content;
  margin-top: 10px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  line-height: 1.6;
  color: #333;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ddd;

  .Header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    grid-gap: 10px;
    padding: 5px;
    background-color: $color-light-grey;
  }

  .Content {
    padding: 15px;
  }
}

.Icon {
  grid-area: Icon;
  display: flex;
  justify-content: flex-end;
}

.Created {
  grid-column: 1;
  grid-row: 1;
  color: $color-text-light;
  padding-top: 10px;
  justify-self: flex-start;
  align-self: center;
}

.Name {
  grid-column: 2;
  grid-row: 1;
  font-weight: bold;
  padding-top: 10px;
  justify-self: flex-start;
  align-self: center;
}

.Profile {
  grid-column: 3;
  grid-row: 1;
  padding-top: 10px;
  justify-self: flex-start;
  align-self: center;
}

.ModuleCode {
  grid-column: 1;
  grid-row: 2;
  color: $color-text-light;
  justify-self: flex-start;
  align-self: center;
}

.Code {
  grid-column: 2;
  grid-row: 2;
  font-weight: bold;
  justify-self: flex-start;
  align-self: center;
}
