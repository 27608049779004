@import '../../../../scss/base/var';

.augurDetailsInner {
  // MainContainer--inner
  display: flex;
  flex-direction: column;
  height: 100%;
}

.devAugurDetailsInner {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.devAugurContainer {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: 1px;

  .sideNavContainer {
    display: flex;
    flex-direction: column;
    border-right: 1px solid $color-light-grey; // Same color as separator in left sidebar and the icon navbar

    .buttonContainer {
      background-color: $color-white;
      bottom: 10px;
      border-bottom-left-radius: 4px;
      display: flex;
      gap: 5px;
      justify-content: space-evenly;
      border-top: 1px solid $color-light-grey;
      padding: 5px;

      .editAugurButtons {
        min-width: 100px;
      }
    }
  }

  .devAugurParent {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: $color-white;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;

    .contentContainer {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }

    .sidebarContainer {
      display: flex;
      flex-direction: column;
      border-left: 1px solid $color-grey; // Same color as separator in left sidebar and the icon navbar
      min-width: 330px;
      width: 20%;
      height: 100%;
      right: 0;
    }
  }
}

.settingsContainer {
  overflow-y: auto;
  flex-grow: 1;
  height: 1px; // This is done so that the "settingsContainer" is the one that scrolls instead of the parent container. Otherwise the button bar would not always be visible for larger pages.
}

.settingsButtonsContainer {
  border-top: 1px solid $color-light-grey; // Same color as the wizard bottom button row
  display: flex;
  gap: 8px;
  padding: 5px;
  justify-content: flex-end;
}

.emptyViewAugur {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.editConfigContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  background-color: $color-white;
}

.header {
  display: flex;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
}

.body {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.leftPanel {
  flex: 1;
  width: 50%;
  padding: 20px;
  border-right: 1px solid #ccc;

  .element {
    height: 50%;
  }
}

.interactionDisabled {
  cursor: not-allowed;
}

.rightPanel {
  flex: 1;
  width: 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow-y: auto;

  .verticalConfigLayout {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}

.footer {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}

.alignEnd {
  display: flex;
  justify-content: flex-end;
}

.lightText {
  color: $color-text-light;
  font-size: 14px;
  padding: 0 0 6px 0;
}

.lightTextItalic {
  color: $color-text-light;
  font-size: 14px;
  font-style: italic;
  padding: 0 0 6px 0;
}

.largeFont {
  font-size: large;
}

.modalTextArea {
  width: 50vw;
  height: 50vh;
}

.showFileContainer {
  display: flex;
  align-items: center;
  margin: 4px 2px 12px 2px;

  &:hover {
    cursor: pointer;

    > span {
      text-decoration: underline;
    }
  }
}

.showFileIcon {
  margin-right: 7px;
}

.showFileText {
  font-size: small;
}

.textInputArea {
  margin-bottom: 20px;
}

.errorMessage {
  color: red;
  margin-top: 10px;
}