@use '../../../../../scss/base/theme.module';
@import '../../../../../scss/base/var';

.startServer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .busy {
    path {
      fill: $color-text-light;
    }
  }
}

.startServerParent {
  width: 800px;
  background-color: white;
  border-radius: 4px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;
}

.startServerHeadlineParent {
  display: flex;
  justify-content: center;
  margin-bottom: auto;
  // height: 60px;
}

.startServerHeadline {
  font-size: 18px;
  font-weight: 400;
}

.startServerMessageParent {
  display: flex;
  justify-content: center;
  height: 20px;
  margin-top: 20px;
}


.startServerButtonParent {
  margin-top: 20px;
}

.imageSelectParent {
  margin-bottom: 40px;
  max-width: 400px;
}

.imageSelectHeadline {
  font-weight: 400;
  margin-bottom: 10px;
  font-size: 15px;
  display: block;
  cursor: default;
}

.resourcesSelectParent {
  margin-bottom: 40px;
}

// ---

.startButtonContainer {
  margin-top: 20px;
  height: 80px;
  position: relative;
}

.startButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  transition: background-color 0.2s ease-in-out;

  &.loading {
    color: transparent;
    cursor: default;
    border: 0;
  }
}

.startButtonProgressBar {
  position: absolute;
  height: 10px;
  width: 0;
  right: 0;
  top: 50%;
  left: 50%;
  border-radius: 200px;
  transform: translateY(-50%) translateX(-50%);
  background: theme.$color-secondary;
}

.startButtonLogo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  opacity: 0;

  svg {
    width: 80px;
    height: 80px;
  }
}
