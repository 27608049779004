@import '../../../../../../../../scss/base/var';

$rowHeight: 22px;
$color-text-light: #a6aeb5;
$color-grey2: #cacdd2;
$color-text: #3a3b3d;
$color-link: $color-primary;

.infoText {
  margin: 8px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.grid {
  display: grid;
  column-gap: 24px;
  row-gap: 8px;
  overflow-x: auto;
  margin: 24px 0 10px;
  grid-template-columns: 1fr 16px auto;
}

.header {
  font-weight: bold;
}

.item {
  display: flex;
  align-items: center;

  &.readOnly {
    opacity: 0.7;
  }
}

.type {
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature {
  display: flex;
  gap: 8px;
  overflow: hidden;
  min-width: 100px;
  margin-right: 5px;
}

.rejected {
  display: flex;
  align-items: center;
  justify-content: center;

  &.rejectedHeader {
    justify-content: start;
  }
}

.discardedOrUsedIcon {
  flex: 0 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconUsed {
}

.iconDiscarded {
  color: $color-red-dark;
}

.iconRejected {
  color: $color-orange-dark;
}

.featureText {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.discarded {
    font-style: italic;
    color: $color-text;
  }
}

.readOnly {
  pointer-events: none;
}

.horizontalBarChartBar {
  fill: $color-primary-highlight;
}

.horizontalBarChartBackgroundBar {
  fill: $color-light-grey;
}

.noFeaturesAvailable {
  font-size: 16px;
  margin-top: 20px;
  color: $color-text-light;
}

.showMoreButton {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  height: 24px;
  bottom: -12px;
  border: 1px solid #dce2eb;
  line-height: 20px;
  border-radius: 12px;
  background: #fff;
  text-decoration: none;
  font-weight: 400;
  color: $color-primary;
  width: 130px;

  &:hover {
    outline: none !important;
    background-color: $color-light-grey;
  }

  .buttonIcon {
    margin-left: 5px;
    margin-right: -5px;
  }
}
