@import "../../../../scss/base/var";

.PipelineTuningChart {
  // padding: 10px;
  width: 100%;
  overflow-x: auto;
  display: flex;

  .react-flow__handle {
    visibility: hidden; // Hide the handle dots
  }

  .PipelineTuningChart--flow-element-node,
  .PipelineTuningChart--flow-element-group {
    border-width: 1px;
    border-radius: 4px;
    border-style: solid;
  }

  .PipelineTuningChart--flow-element-node {
    width: 170px;
    min-height: 59px; // = 2 lines of text + the subtitle
    border-color: $color-dark-grey;
    transition: border-color 0.1s ease-in-out, color 0.1s ease-in-out,
    background-color 0.1s ease-in-out;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 8px; // #9
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      border-color: $color-primary;
      background-color: $color-primary;
      color: $color-white;
    }

    &.PipelineTuningChart--flow-element-node-selected {
      border-color: $color-primary !important;
      background-color: $color-primary;
      color: $color-white;
    }

    &.PipelineTuningChart--flow-element-node-type-classifier {
      border-color: $color-text;
    }

    &.PipelineTuningChart--flow-element-node-type-transformer {

    }
  }

  .PipelineTuningChart--flow-element-group {
    border-color: $color-grey;
    background-color: $color-light-grey;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 8px; // #9

    &--placeholder {
      border-color: $color-light-grey !important;
      color: rgba(0, 0, 0, 0) !important;

      * {
        color: rgba(0, 0, 0, 0) !important;
      }
    }

    .PipelineTuningChart--group-title-container {
      margin: 5px 5px 10px 5px;

      .PipelineTuningChart--group-title {
        color: $color-text-light;
        text-align: center;
        display: block;
        font-weight: 400;
      }
    }
  }

  .PipelineTuningChart--node {
    width: 100%;
    height: 100%;
    padding: 5px;

    &.PipelineTuningChart--node-inactive {
      .PipelineTuningChart--title {
        color: $color-text-light;
      }
    }
  }

  .PipelineTuningChart--title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.2px;
    margin-bottom: 5px;

    span {
      text-align: center;
    }
  }

  .PipelineTuningChart--node-type {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-text-light;
    font-size: 12px;

    span {
      text-align: center;
    }
  }

  .PipelineTuningChart--group {
    .PipelineTuningChart--group-node {
      border: 1px solid $color-dark-grey;
      border-radius: 4px;
      margin: 5px;
      width: 170px;
      min-height: 59px; // = 2 lines of text + the subtitle
      background-color: $color-white;
      transition: border-color 0.1s ease-in-out, color 0.1s ease-in-out;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        border-color: $color-primary !important;
        background-color: $color-primary;
        color: $color-white;
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &.PipelineTuningChart--group-node-selected {
        border-color: $color-primary !important;
        background-color: $color-primary;
        color: $color-white;
      }

      &.PipelineTuningChart--group-node-type-classifier {
        border-color: $color-text;
      }

      &.PipelineTuningChart--group-node-type-transformer {

      }
    }
  }
}

