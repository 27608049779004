@import '../../../../../../../scss/base/var';

$color-text-light: #a6aeb5;
$color-text: #3a3b3d;

.grid {
  display: grid;
  grid-template-columns: 130px auto;
  column-gap: 24px;
  row-gap: 8px;
  overflow-x: auto;
  margin: 24px 0;
}

.header {
  font-weight: bold;
}

.item {
  &.readOnly {
    opacity: 0.7;
  }
}

.rejected {
  display: flex;
  align-items: center;
  justify-content: center;

  &.rejectedHeader {
    justify-content: start;
  }
}

.feature {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 100px;

  &.discarded {
    font-style: italic;
    color: $color-text;
  }
}

.readOnly {
  pointer-events: none;
}

.noFeaturesAvailable {
  font-size: 16px;
  margin-top: 20px;
  color: $color-text-light;
}
